.search-wrapper {
  position: relative;
}

.search-wrapper .search-box {
  width: 100%;
  padding-right: 20px;
  box-sizing: border-box;
}

.search-wrapper .search-box:placeholder-shown + .reset-search-button {
  opacity: 0;
  pointer-events: none;
}

.search-wrapper .reset-search-button {
  position: absolute;
  border: none;
  display: block;
  width: 26px;
  height: 26px;
  line-height: 16px;
  font-size: 26px;
  border-radius: 50%;
  top: 0;
  bottom: 0;
  right: 5px;
  margin: auto;
  background: white;
  padding: 0;
  outline: none;
  cursor: pointer;
  transition: 0.1s;
}
