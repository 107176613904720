html {
  @apply bg-gray-100;
}

:root {
  --amplify-primary-color: #d71440;
  --amplify-primary-tint: #ba112d;
  --amplify-primary-shade: #ba112d;
  --amplify-background-color: #ba112d;
  --amplify-components-button-primary-background-color: #d71440;
}

[data-amplify-authenticator] {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 80vh;
}

.amplify-button[data-variation="primary"] {
  background: var(--amplify-primary-color);
}

.amplify-button:hover[data-variation="primary"] {
  background: rgba(186, 17, 45, 0.9);
}
.btn {
  @apply py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium cursor-pointer;
}

.btn-success {
  @apply text-white hover:bg-green-600 bg-green-500;
}

.btn-cancel {
  @apply text-white hover:bg-red-600 bg-red-500;
}

.btn-primary {
  background: var(--amplify-primary-color);
}

.btn-clear {
  @apply relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50;
}

.btn-disable {
  @apply relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-gray-300;
}

.terminal-table tr:nth-of-type(even) {
  @apply bg-gray-50;
}

.terminal-table td {
  @apply px-6 py-3 whitespace-nowrap text-sm text-gray-800;
}

.terminal-table th {
  @apply px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider;
}

.amplify-tabs {
  display: none !important;
}

.edit-data-row {
  display: flex;
  align-items: center;
  @apply py-2 px-2 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4;
}

.edit-data-row:hover {
  @apply bg-gray-100;
}

.edit-data-input {
  @apply block w-full p-2 px-3 border border-gray-200 rounded-md shadow-sm;
}
.disabled {
  @apply bg-gray-200;
}
.edit-data-input-error {
  @apply block w-full p-2 px-3 border border-red-500 rounded-md shadow-sm;
}
.no-results {
  padding: 24px;
}

.errored-icon-text {
  @apply text-red-500;
}
.errored-icon-text:before {
  position: relative;
  left: -2px;
  content: "\00d7";
  font-size: 16px;
}

.success-icon-text {
  @apply text-green-600;
}
.success-icon-text:before {
  position: relative;
  left: -2px;
  content: "\2713";
  font-size: 16px;
}
